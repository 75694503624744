import * as React from 'react'

import '../index.scss'

import { Helmet } from 'react-helmet'

const IndexPage = () => (
    <main>
        <Helmet title="Rens Vleeming" defer={false} />
        <section className="section">
            <div className="content">
                <h1>Rens Vleeming</h1>
            </div>
        </section>
    </main>
)

export default IndexPage
